import React from 'react';
import profilepic from './profilepic.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={profilepic} className="App-logo" alt="profilepic" />
        <h1 className="App-header-title">Thousanda</h1>
      </header>
      <p>音声スタートアップでSREをしているThousandaのwebサイトです．おもいっきり作りかけですがひとまずそのまま公開しています．これぞプロセスエコノミー！ (違う</p>
      <h2>リンク集</h2>
      <h3>Twitter</h3>
      <a href="https://twitter.com/thousan_da">@thousan_da</a>

      <h3>Note</h3>
      <a href="https://note.com/thousanda">https://note.com/thousanda</a>

      <h3>GitHub</h3>
      <a href="https://github.com/thousanda">https://github.com/thousanda</a>

      <h3>Podcast</h3>
      <a href="https://anchor.fm/thousanda">千載一遇のラジオ</a>
      <p>千載一遇のラジオです．ひとりごとを話すPodcast</p>

      <a href="https://anchor.fm/fm-omurice">FMオムライス</a>
      <p>オムライスの話だけをするPodcast</p>

      <h3>Voicy</h3>
      <a href="https://voicy.jp/search/senchan">voi-chord</a>
      <p>音声配信プラットフォームVoicyのエンジニアチームで運営する音声配信</p>
    </div>
  );
}

export default App;
