import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Happy } from "./components/Happy";
import App from "./App";
import {NotFound} from "./components/NotFound";


export const RouterConfig:React.VFC =() => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<App />} />
          <Route path="happy" element={<Happy />} />

          {/* TODO: status codesを404にする */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}